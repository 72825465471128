<template>
  <div>
    <v-simple-table
      class="table-sm"
      v-if="show_table"
      style="transition: 0.3s ease-in-out"
    >
      <tbody>
        <tr v-if="!editing">
          <td colspan="3">
            <v-alert
              v-if="!settings"
              dense
              type="info"
              color="blue lighten-5 blue--text"
            >
              <li>
                If you want to write the value after printing this invoice,
                leave the value input empty.
              </li>
              <li>Rows without labels would not be saved.</li>
            </v-alert>
          </td>
        </tr>
        <tr v-for="(header, index) in headers" :key="index">
          <td class="disabled">
            <v-text-field
              placeholder="label"
              v-model="header.label"
              dense
              @blur="$emit('headerChanged', { headers, show_table })"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              placeholder="Value"
              v-model="header.value"
              dense
              clearable
              @blur="$emit('headerChanged', { headers, show_table })"
              @click:append="$emit('headerChanged', { headers, show_table })"
            ></v-text-field>
          </td>

          <td>
            <v-btn icon color="red" @click="remove(index)">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <v-btn
              @click="add"
              x-small
              :block="!editing || hideDone"
              text
              color="blue"
              rounded
              id="InvoiceExtraHeaders"
            >
              <v-icon>mdi-plus</v-icon>
              Row
            </v-btn>
            <v-btn
              v-if="editing && !hideDone"
              @click="$emit('done', { headers, show_table })"
              color="green"
              depressed
              dark
              rounded
              >Done
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-btn
      :color="show_table ? 'red' : 'blue'"
      small
      text
      v-if="!editing && !settings"
      block
      rounded
      id="InvoiceExtraHeaders"
      @click="
        show_table = !show_table;
        $emit('headerChanged', { headers, show_table });
      "
    >
      {{ show_table ? "Remove" : "Add" }} extra header Info.
    </v-btn>
    <v-btn
      block
      rounded
      depressed
      dark
      :color="
        $store.state.user.user_infor.current_business.invoice_settings
          .invoice_color
      "
      class="mt-3"
      v-if="settings"
      @click="saveHeaders"
      :loading="savingHeaders"
      >Save Headers
    </v-btn>

    <invoice-extra-headers></invoice-extra-headers>
    <v-snackbar v-model="show_success_message"
      >{{ success_message }}
    </v-snackbar>
    <v-snackbar v-model="show_error_message">{{ error_message }} </v-snackbar>
  </div>
</template>

<script>
import InvoiceExtraHeaders from "../tour/InvoiceExtraHeaders";
import eventBus from "@/utils";

export default {
  computed: {
    eventBus() {
      return eventBus;
    },
  },
  components: { InvoiceExtraHeaders },
  props: ["defaultHeaders", "showHeaders", "editing", "hideDone", "settings"],
  name: "extraHeadersComponent",
  data() {
    return {
      headers: [],
      show_table: false,
      savingHeaders: false,
      success_message: "",
      error_message: "",
      show_success_message: false,
      show_error_message: false,
    };
  },
  watch: {
    defaultHeaders() {
      this.headers = this.defaultHeaders;
    },
  },
  methods: {
    remove(index) {
      this.headers.splice(index, 1);
      this.$emit("headerChanged", {
        headers: this.headers,
        show_table: this.show_table,
      });
      console.log("headerRemoved");
      eventBus.$emit("headerRemoved", {
        headers: this.headers,
      });
    },
    add() {
      this.headers.push({ label: "", value: "" });
      console.log("headerAdded");
      this.$emit("headerChanged", {
        headers: this.headers,
        show_table: this.show_table,
      });
    },
    getHeaders() {
      axios.get("/api/invoice/headers").then((res) => {
        this.headers = res.data;
      });
    },
    saveHeaders() {
      this.savingHeaders = true;
      console.log("Saving headers...");
      const data = { invoiceHeaders: this.headers };
      axios.post("/api/invoice/headers", data).then((res) => {
        this.success_message = "Invoices headers saved";
        this.show_success_message = true;
        this.savingHeaders = false;
        console.log("Saved headers...");
        eventBus.$emit("save_headers", this.headers);
      });
    },
  },
  mounted() {
    this.show_table = !!this.settings;

    if (this.editing) {
      this.headers = this.defaultHeaders;
      this.show_table = this.showHeaders;
    } else {
      this.getHeaders();
    }
  },
};
</script>

<style scoped></style>
